import React from 'react'
import styled from 'styled-components'

import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'

const HeaderAndButtonWithGreenBackground = ({handleClick, header, buttonText}) => {
  return (
    <Wrapper>
      <div className="content">
        <h3>{header}</h3>
      </div>
      <div className="buttons">
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_S}
            padding={BUTTON_SIZE.PADDING_S}
            fontSize={BUTTON_SIZE.FONT_SIZE_S}
            background={BUTTON_COLOR.GREEN}
            backgroundHover={BUTTON_COLOR.GREEN_HOVER}
            color={BUTTON_COLOR.TEXT_COLOR_WHITE}
            text={buttonText}
            handleClick={handleClick}
          />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
	background: linear-gradient(155deg, rgb(190 236 213) 15%, rgb(210 242 226) 70%, rgb(190 236 213) 0);
	margin: 0 auto;
	padding: 40px;
	max-width: 960px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	@media screen and (max-width: 991px) {
		width: 100%;
	};

	.content {
		h3 {
			font-size: 28px;
			${'' /* text-align: center; */}
			margin: 0px 0px 16px 0px;
		};
		@media screen and (max-width: 768px) {
			h3 {
				font-size: 22px;
			}
		};

	};
	.buttons {
		display: flex;
		justify-content: flex-end;
		align-Items: center;
	};
`
export default HeaderAndButtonWithGreenBackground