import React from 'react'
import styled from 'styled-components'

const ClubTag = () => {
  return (
    <Wrapper>
      <p>Club</p>
    </Wrapper>
  ) 
}


const Wrapper = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: center;
  background: linear-gradient(to right, rgb(86 39 217), rgb(119 72 252));
  padding: 8px;
  border-radius: 10px;
  margin: 0 10px;
  p {
    color: white !important;
    font-size: 21px !important;
    margin: 0;
    font-family: Poppins, Helvetica, Arial, sans-serif;
  };
  @media screen and (max-width: 768px) {
    padding: 4px;
    border-radius: 5px;
    margin: 0 5px;
    p {
      font-size: 18px !important
    }

	};
`


export default ClubTag