export const blog = (pageHeader, pageURL) => {
  return {
    "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Homepage",
      "item": "https://societyone.com.au"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": pageHeader,
      "item": pageURL  
    }]
  }
}

export const getArticleMarkupForBlog = (post) => {
  return {
    "@type": "BlogPosting",
    "headline": post.heading,
    "datePublished": post.date,
  }
}