import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { FaChevronRight } from "react-icons/fa"
import { Link } from 'gatsby'

import TypeTag from '../TypeTag'

const BlogsOrGuidesItem = (props) => {

	const { isBlog, item } = props

	return (
		<Link to={isBlog ? `/blog/${item.slug}` : `/guide/${item.slug}`}>
			<Item item={item}>
				<div className='imgWrapper'>
					{item.heroImage && <GatsbyImage image={item.heroImage?.gatsbyImageData} alt={item.heroImage?.title}/>}
				</div>
				{isBlog && 
					<TypeTag item={item.type} background={"white"}>
						{item.isShowDate &&
							<div className='date'>
								<p>• {item.date}</p>
							</div>
						}
					</TypeTag>
				}
				<h2>
					{item.heading.childMarkdownRemark?.excerpt || item.heading}
				</h2>
				<div className="itemArrow">
					<p>{isBlog ? 'Read Article' : 'Explore Guide'}</p>
					<div>
						<FaChevronRight />
					</div>
				</div>
			</Item>
		</Link>
	)
}

const Item = styled.div`
	cursor: pointer;
	.gatsby-image-wrapper {
		border-radius: 8px;
		@media screen and (max-width: 480px) {
			margin: 0 auto;
			height: auto;
		};

	};
	.imgWrapper {
		width: 320px; 
		height: 192px; 
		margin-bottom: 10px;
		@media screen and (max-width: 991px) {
			width: 30vw; 
		};
		@media screen and (max-width: 768px) {
			width: 43vw; 
		};
		@media screen and (max-width: 480px) {
			width: 100%; 
		};

		img {
			transition: 0.5s;
			width: 320px;
			height: 192px;
			object-fit: cover;
			@media screen and (max-width: 991px) {
				width: 30vw; 
			};
			@media screen and (max-width: 768px) {
			width: 43vw; 
			};
			@media screen and (max-width: 480px) {
			width: 100%; 
			};

		};
	};

	.date {
		p {
			font-size: 14px; 
			@media screen and (max-width: 768px) {
				font-size: 12px;
			};
		}

	}
	.itemArrow {
		display: flex;
		align-items: center;
		p {
			font-size: 16px;
		};
		div {
			padding: 0 10px; 
			display: flex; 
			align-items: center; 
			font-size: 16px;
			transition: 0.5s;
		}
	};
	&:hover {
		color: rgb(38 75 101);
		.gatsby-image-wrapper {
			border-radius: 8px;
		}
		img {
			transform: scale(1.05);
			transition: 0.5s;
		};
		.itemArrow {
			div {
				padding: 0 20px;
				transition: 0.5s;
			}
		}
	}
	h2 {
		font-size: 28px; 
		margin: 10px 0; 
		width: 320px;
		@media screen and (max-width: 991px) {
			width: 30vw; 
			font-size: 20px; 
		};
		@media screen and (max-width: 480px) {
			width: 100%; 
		};
	}
`

export default BlogsOrGuidesItem
