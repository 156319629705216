import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import TypeTag from '../blocks/TypeTag'
import ClubTag from '../blocks/ClubTag'

const Hero = ({ post }) => {
  
  return (
    <HeroWrapper>
      <ImgAndHeader image={post.image?.fluid.src}>
        <BigScreenWrapper>
          {post.type === "Credit Score Club" ? 
            <CscTag>
              <div className='creditScoreHeader'>Credit Score</div>
              <ClubTag/>
              {/* <p className='creditScoreDate'>• {post.date}</p> */}
            </CscTag>
            :
            <TypeTag item={post.type} background={"rgb(244 245 246)"}>
              {/* <p className='creditScoreDate'>• {post.date}</p> */}
            </TypeTag>
          }
          <Header>
            {post.heading}
          </Header>
          
          <SubHeader>
            {post.subHeader}
          </SubHeader>
        </BigScreenWrapper>
      </ImgAndHeader>
      <ShareAndContent>
        <ImageForSmallScreen>
          <GatsbyImage image={post.heroImage?.gatsbyImageData} alt={post.heroImage?.title}/>
        </ImageForSmallScreen>
        <Share>
          <div>SHARE</div>
          <div className="addthis_inline_share_toolbox">           
          </div>
        </Share>
        <ContentAndDate>
          <Content
            dangerouslySetInnerHTML={{
              __html: post.content.childMarkdownRemark.html,
            }}
          />
          {/* <p className='creditScoreDate'>• {post.date}</p> */}
          {post.isShowDate && <p className='creditScoreDate'>Published on {post.date}</p>}
        </ContentAndDate>
        
      </ShareAndContent>

    </HeroWrapper>
  )
}

const ContentAndDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .creditScoreDate {
    font-size: 14px;
    color: rgb(38 75 101) ! important;
  }
  @media screen and (max-width: 768px) {
    .creditScoreDate {
      color: rgb(38 75 101) ! important;
    }
	};

`
const ImageForSmallScreen = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto 20px;
    border-radius: 10px;
    img {
      border-radius: 10px;
    }
	};
`
const HeroWrapper = styled.div`
  max-width: 1440px;
  height: auto;
  margin: 0 auto;
`
const ImgAndHeader = styled.div`
  width: 100%;
  padding: 350px 30px 40px 30px;
  background-image: ${props => props.image && `linear-gradient(180deg,transparent,rgba(0,0,0,.66)), url(${props.image})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media screen and (max-width: 991px) {
    background-position: center;

	};
  @media screen and (max-width: 768px) {
    background: none;
    padding: 0;
	};

`
const BigScreenWrapper = styled.div`
  .creditScoreDate {
    font-size: 14px;
    color: white ! important;
  }
  @media screen and (max-width: 768px) {
    margin: 20px 30px;
    .creditScoreDate {
      color: rgb(38 75 101) ! important;
    }
	};
`

const Header = styled.h1`
  color: white;
  width: 90%;
  margin: 30px 0;
  @media screen and (max-width: 991px) {
    font-size: 33px;
    margin: 20px 0;
	};
  @media screen and (max-width: 768px) {
    color: rgb(38 75 101);
    font-size: 30px;
    width: 100%;
	};
`

const SubHeader = styled.div`
  color: white;
  width: 90%;
  font-size: 22px;
  @media screen and (max-width: 991px) {
    font-size: 18px;
	};
  @media screen and (max-width: 768px) {
    display: none;
	};

`
const ShareAndContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 1232px;
  margin: 60px auto 0;
  padding: 0 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;

	};

`
const Share = styled.div`
  width: 200px;
  margin-right: 40px;
  position: sticky;
  top: 90px;
  div {
    width: 200px;
    font-size: 15px;
    color: #79838d;
    font-family: QuicksandBold;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0px;

	};

`
const Content = styled.div`
  max-width: 992px;
  img {
    width: 70%;
    margin: 0 auto;
  }
`
const CscTag = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  .creditScoreHeader {
    font-family: PoppinsBold;
    font-size: 21px
  }
  @media screen and (max-width: 768px) {
    color: var(--base-navy);
    .creditScoreHeader {
      font-size: 16px
    }
	};
`

export default Hero
