import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import BlogsAndGuidesPreview from '../blocks/BlogsAndGuidesPreview'
import { filterByBlogsTypeAndHeader } from '../../utils/blogRelated'

const BlogsPreview = ({type, header}) => {

  const data = useStaticQuery(graphql`
    query BlogsPreview {
      allContentfulPageBlogPost(
        sort: {fields: date, order: DESC}
      ) {
        nodes {
          isShowDate
          heading
          date(formatString: "MMM Do, YYYY")
          slug
          type
          heroImage {
            gatsbyImageData(placeholder:NONE)
            title
          }
        }
      }
    }
  `)

  const result = data.allContentfulPageBlogPost.nodes
  const blogs = filterByBlogsTypeAndHeader(type, header, result).slice(0,3)

  return (
    <BlogsPreviewWrapper>
      <BlogsAndGuidesPreview contents={blogs} isBlog={true} />
    </BlogsPreviewWrapper>
  )
}
const BlogsPreviewWrapper = styled.div`
  margin: 0 auto;
	max-width: 1024px;
	@media screen and (max-width: 991px) {
		max-width: 100%;
	};

`
export default BlogsPreview