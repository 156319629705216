import React from 'react'
import styled from 'styled-components'
import { blogTypeColor } from '../../utils/blogRelated'

const TypeTag = ({item, background, children}) => {
  return (
    <Wrapper>
      <TypeTagWrapper item={item} background={background}>
        <div className='dot'></div>
        <div className='typeText'>{item}</div>
      </TypeTagWrapper>
      {children}
    </Wrapper>
  ) 
}


const Wrapper = styled.div`
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
  flex-wrap: wrap;
`
const TypeTagWrapper = styled.div`
  margin: 8px 12px 8px 0;
  padding: 8px 12px; 
  background: ${props => props.background || 'white'}; 
  border-radius: 40px; 
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  .dot {
    background: ${props => blogTypeColor(props.item)}; 
    ${'' /* background: rgb(29 188 110);  */}
    width: 10px; 
    height: 10px; 
    border-radius: 50%;
    margin-right: 10px;
  };
  .typeText {
    font-size: 10px; 
    font-family: QuicksandBold;
  }
  

`

export default TypeTag