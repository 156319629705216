import React, { useCallback, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { MdOutlineEmail, MdClose } from 'react-icons/md'
import { Link } from 'gatsby'
import Helmet from "react-helmet"

import Seo from '../components/seo'
import { blog, getArticleMarkupForBlog } from '../support/structuredDataConfig/blog'
import Layout from '../layouts/layout'
import Hero from '../components/ForBlogPage/hero'
import BlogsPreview from '../components/ForBlogPage/BlogsPreview'
import ContentContainer from '../components/UI/ContentContainer'
import HeaderAndButtonWithGreenBackground from '../components/blocks/HeaderAndButtonWithGreenBackground'
import * as api from '../api'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../support'
import HomeButton from '../components/UI/Buttons/HomeButton'
import addQuery from '../utils/addQuery'

const BlogPostTemplate = ({ data, path }) => {
  const post = data.contentfulPageBlogPost
  const site = data.site
  const [isShown, setIsShown] = useState(false)

  useEffect (() => {
    setTimeout(() => {
      setIsShown(true)
    }, 8000);
  }, [])

  const handleClose = useCallback (() => {
    setIsShown(false)
  }, [setIsShown])

  const handleSubmit = (e) => {
    e.preventDefault()
    api.getInsideScoop(inputState.firstName, inputState.lastName, inputState.email)
		.then((res) => {
      if (res.status === 200) {
        setSubmissionResponse("Success");
      }
      else {
        setSubmissionResponse("Error");
      }
    })
    .catch((err) => {
      setSubmissionResponse("Error");
    })
}

  const [inputState, setInputState] = useState({
    firstName: '',
    email: '',
  })

  const [submissionResponse, setSubmissionResponse] = useState("")

  const handleChange = (input) => (e) => {
    setInputState(
      {
        ...inputState,
        [input]: e.target.value,
      }
    )
  }
  const handleClick = () => addQuery(URL.QUOTE)
  const previousStructuredData = blog(post.heading, `${site.siteMetadata.siteUrl}${path}`)
  const mmeStructuredData = getArticleMarkupForBlog(post)
  const structuredData = { ...previousStructuredData, "article": {...mmeStructuredData} }
  return (
    <Layout>
      <Seo  
        title={!post.seoMetaTitle ? post.heading : post.seoMetaTitle} 
        description={!post.seoMetaDescription ? post.subHeader : post.seoMetaDescription} 
        image={post.seoMetaImage?.fluid?.src} 
        path={path}
        structuredData={structuredData}
      />
      <Helmet>
        <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6036e2612737a33a"/>
      </Helmet>
      <Hero post={post} />
      <ContentContainer>
        <GetAPersonalLoanToday>
          <HeaderAndButtonWithGreenBackground 
            handleClick={handleClick}
            header={'Get a personal loan today!'}
            buttonText={'Get My Rate'}
          />
        </GetAPersonalLoanToday>

        <BlogsPreviewWrapper>
          <h2>You may also like</h2>
          <Hr/>
          <BlogsPreview type={post.type} header={post.heading} />
        </BlogsPreviewWrapper>
        <GetTheInsideScoop isShown={isShown}>
          <CloseIcon onClick={handleClose}>
            <MdClose />
          </CloseIcon>
          <Header>
            <Icon>
              <MdOutlineEmail/>
            </Icon>
            <div className='getTheInsideScoopHeader'>Get the inside scoop</div>
          </Header>
          <p>News, tips and offers straight to your inbox.</p>
          {
            submissionResponse === ""
            &&
            <NameAndEmail>
              <form onSubmit={handleSubmit}>
                <FormWrap>
                  <label htmlFor="firstName">
                    <input type="text" name="firstName" required placeholder='First name' value={inputState.firstName} onChange={handleChange('firstName')} id='firstName'/>
                  </label>
                  <label htmlFor="lastName">
                    <input type="text" name="lastName" required placeholder='Last name' value={inputState.lastName} onChange={handleChange('lastName')} id='lastName'/>
                  </label>
                  <label htmlFor="email">
                    <input type="email" name="email" required placeholder='Email address' id='email'value={inputState.email} onChange={handleChange('email')}/>
                  </label>
                </FormWrap>
                <div className="description">
                  <p>By clicking 'Subscribe' you agree to our 
                    <Link to='/privacypolicy'> <span>Privacy Policy</span> </Link>
                    and
                    <Link to='/legal'> <span>Terms Of Use</span> </Link>
                  </p>
                </div>
                <HomeButton
                  height={BUTTON_SIZE.HEIGHT_L}
                  padding={BUTTON_SIZE.PADDING_L}
                  fontSize={BUTTON_SIZE.FONT_SIZE_L}
                  background={BUTTON_COLOR.BLUE}
                  backgroundHover={BUTTON_COLOR.BLUE_HOVER}
                  color={BUTTON_COLOR.TEXT_COLOR_WHITE}
                  text='Subscribe'
                  type="submit"
                  margin="0px"
                />
              </form>
            </NameAndEmail>
          }
          {
            submissionResponse === "Success"
            &&
            <div className='success'>
              Thank you for your submission!
            </div>
          }
          {
            submissionResponse === "Error"
            &&
            <div className='error'>
              Sorry, some error occurred. Please try again in some time.
            </div>
          }
        </GetTheInsideScoop>
      </ContentContainer>
    </Layout>
  )
}

const Hr = styled.hr`
  background-color: rgba(225 225 225); 
  height: 1px; 
  border: none; 
  margin: 0 0px 30px 0px;

`
const BlogsPreviewWrapper = styled.div`
  margin: 0 auto;
  padding: 0 30px;
  h2 {
    margin: 20px;
  }
  @media screen and (max-width: 991px) {
    padding: 0;
		};

`
const GetAPersonalLoanToday = styled.div`
  margin: 0 0 100px 0; 
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .getTheInsideScoopHeader {
    font-size: 24px;
    font-family: PoppinsBold;
  }
  p {
    margin: 10px 0;
  }
`
const NameAndEmail = styled.div`
	@media screen and (max-width: 768px) {
    width: 100%;
  }
  .description {
    p {
      font-size: 12px;
      margin: 20px 0;
      span {
        text-decoration: underline;
      }
    }
  }
`
const CloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
`
const Icon = styled.div`
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 10px;
  margin-right: 20px;
  svg {
    width: 20px;
  }
`
const FormWrap = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@media screen and (max-width: 768px) {
    display: grid;
    grid-gap: 20px 10px;
    grid-template-columns: 1fr 1fr;
    label {
      width: 100%;
      input {
        width: 100%;
      }
    }
    label:nth-child(3) {
      grid-column: span 2;
    }
  };

	input {
		padding: 0 24px;
		margin: 0 15px 0 0;
		height: 44px;
		width: 189px;
		border-radius: 8px;
		outline: none;
		border: 1px solid rgba(100, 100, 111, 0.3);
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 19px 0px;
	};

`
const GetTheInsideScoop = styled.div`
  display: ${props => props.isShown ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: rgb(236 237 250);
  padding: 28px 24px;
  position: fixed;
  z-index: 900;
  bottom: 30px;
  border-radius: 16px;
  max-width: 639px;
  @media screen and (max-width: 768px) {
    max-width: 100vw;
    width: 100vw;
    left: 0;
  };

  .success{
		border-radius: 4px;
		background-color: var(--base-green-light);
		color: var(--base-navy);
		font-family: QuicksandSemiBold;
		padding: 20px;
	}
	.error{
		border-radius: 4px;
		background-color: var(--base-red);
		color: var(--base-navy);
		font-family: QuicksandSemiBold;
		padding: 20px;
	}
`
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
  ) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulPageBlogPost(slug: { eq: $slug }) {
      isShowDate
      slug
      heading
      type
      subHeader
      date
      content {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(placeholder:NONE)
        title
      }
      image {
        fluid(quality: 100, maxWidth: 1440) {
          src
        }
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaImage {
        fluid {
          src
        }
      }
    }
  }
`
