import React from 'react'
import styled from 'styled-components'

import BlogsOrGuidesItem from './BlogsOrGuidesItem'

const BlogsAndGuidesPreview = ({ contents, isBlog }) => {
  return (
    <BlogsOrGuidesItems>
      {contents.map((item,index) => 
        <BlogsOrGuidesItem isBlog={isBlog} item={item} key={index}/>
      )}
    </BlogsOrGuidesItems>
  )
}

const BlogsOrGuidesItems = styled.div`
	margin: 0px;
	padding: 0px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
  @media screen and (max-width: 768px) {
    display: grid;
    grid-gap: 20px 10px;
    grid-template-columns: repeat(2, 1fr);
  };
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  };

`

export default BlogsAndGuidesPreview